import { useEffect } from "react";
import Footer from "../component/Footer";
import NavBarre from "../component/NavBarre";
import React from 'react';

function MentionLegal(){



    return(

        <div className="containerPage ">

            <NavBarre/>

            <div className="container-mention">
        <h1 className="h1mention text-green-600">Mention légal ALC Paysages</h1>

        <div className="para para1">

           <h2 className="title-mention">Responsable de la publication : </h2><br/>

            <p>ALC Paysages, Anthony Wasselin <br/>

            Entreprise Individuelle, SIREN : 981663404 <br/>

            2 rue du chene liege 77176, Savigny-le-temple <br/>

            06 52 61 13 91   <br/>

            alc.paysages@gmail.com</p>
            </div>

            <div className="para para2">

                <h2 className="title-mention">Directeur de la publication :</h2>

                <p>Anthony Wasselin </p>


             </div>

               <div className="para para3">

                <h2 className="title-mention">Hébergeur :</h2>

                <p> </p>


             </div>

                 <div className="para para4">

                <h2 className="title-mention">Site web créé par :</h2>

                <p>Matthias BEGOT & Hamza BENKEJJANE</p>


             </div>
             <div className="para para5">

                <h2 className="title-mention">Droits d'auteur :</h2>

                <p>Le contenu de ce site web (textes, images, vidéos, etc.) est protégé par les lois sur le droit d'auteur et appartient à ALC Paysages ou à ses <br/>
                    partenaires, sauf indication contraire. Toute reproduction ou utilisation du contenu sans autorisation écrite préalable est strictement interdite.
                 </p>


            </div>

            <div className="para para6">

                <h2 className="title-mention">Collecte de données personnelles :</h2>

                <p>Les informations collectées sur ce site sont destinées à ALC Paysages et peuvent être utilisées à des fins de communication, de gestion des <br/>
                    commandes ou d'analyse statistique. Conformément à la loi Informatique et Libertés du 6 janvier 1978 et au Règlement Général sur la Protection des <br/>
                    Données (RGPD), vous disposez d'un droit d'accès, de rectification et de suppression des données vous concernant. Vous pouvez exercer ce droit <br/>
                    en nous contactant à l'adresse alc.paysages@gmail.com.
                </p>


            </div>

            <div className="para para7">

                <h2 className="title-mention">Cookies :</h2>

                <p>Ce site utilise des cookies pour améliorer l'expérience de l'utilisateur. Les cookies sont de petits fichiers texte stockés sur votre ordinateur qui<br/>
                    permettent de vous reconnaître lors de votre prochaine visite et de personnaliser le site en fonction de vos préférences. En naviguant sur ce site, <br/>
                    vous acceptez l'utilisation de cookies. Vous pouvez toutefois configurer votre navigateur pour refuser l'installation des cookies.<br/>
                </p>


            </div>

            <div className="para para8">

                <h2 className="title-mention">Liens externes :</h2>

                <p>Ce site peut contenir des liens vers des sites externes sur lesquels ALC Paysages n'a aucun contrôle. Nous déclinons toute responsabilité quant au <br/>
                    contenu de ces sites externes. La présence de ces liens ne signifie pas que ALC Paysages approuve le contenu ou les services proposés par ces<br/>
                    sites.
                </p>


            </div>


            <div className="para para9">

                <h2 className="title-mention">Limitation de responsabilité :</h2>

                <p>ALC Paysages s'efforce de fournir des informations exactes et à jour sur ce site, mais ne peut garantir l'exactitude, l'exhaustivité ou la pertinence <br/>
                   des informations fournies. En conséquence, ALC Paysages décline toute responsabilité en cas d'erreurs, d'omissions ou de résultats obtenus par<br/>
                   l'utilisation des informations disponibles sur ce site.
                </p>


            </div>
            </div>

            <Footer/>
             </div>
    )

}

export default MentionLegal;
