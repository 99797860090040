import React, { useState } from 'react';
import NavBarre from "../component/NavBarre";

function AdminPannel() {
    const [realisationFile, setRealisationFile] = useState(null);

    const [beforeFile, setBeforeFile] = useState(null);
    const [afterFile, setAfterFile] = useState(null);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');


    const handleRealisationChange = (e) => {
        setRealisationFile(e.target.files[0]);
    };
    const handleBeforeChange = (e) => {
        setBeforeFile(e.target.files[0]);
    }
    const handleAfterChange = (e) => {
        setAfterFile(e.target.files[0]);
    }
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }
    const handleContentChange = (e) => {
        setContent(e.target.value);
    }

    const handleRealisationSubmit = (e) => {
        e.preventDefault();

        if (!realisationFile) {
            alert("Please select a file.");
            return;
        }
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append("realisation", realisationFile);

        fetch('https://alc-paysages.fr/api/realisation', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to upload realisation');
                }
            })
            .then(result => {
                alert('Realisation uploaded successfully');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred while uploading the realisation');
            });
    };

    const handleSubmitTitle = (e) => {
        e.preventDefault();
        if (!beforeFile && !afterFile){
            alert("Please select a file.");
            return
        }

        const formData = new FormData();
        formData.append("before", beforeFile);
        formData.append("after", afterFile);
        formData.append("title", title);
        formData.append("content", content);
        const token = localStorage.getItem('token');

        fetch('https://alc-paysages.fr/api/title', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to upload realisation');
                }
            })
            .then(result => {
                alert('before/after uploaded successfully');
                window.location.reload();
            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred while uploading the before/after');
            });



    }

    return (
        <>
            <NavBarre />
            <div className="flex justify-center items-center min-h-screen">
                <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-3xl">
                    <h1 className="text-xl text-center mb-8">Admin Pannel</h1>

                    <form className="mb-6 text-center" onSubmit={handleRealisationSubmit}>
                        <h2 className="text-lg mb-4">Réalisations</h2>
                        <div className="border p-6 rounded-lg shadow-md">
                            <input
                                type="file"
                                className="block text-center w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 cursor-pointer"
                                onChange={handleRealisationChange}
                            />
                        </div>
                        <button type="submit" className="inline-flex justify-center mt-4 py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-200 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                            Submit
                        </button>
                    </form>

                    <h2 className="text-lg mb-4 text-center">Or</h2>

                    <form className="space-y-6" onSubmit={ handleSubmitTitle }>
                        <div className="flex justify-center space-x-8">
                            <div className="text-center">
                                <h3 className="mb-2">Avant</h3>
                                <div className="border p-6 rounded-lg shadow-md">
                                    <input type="file" className="inputLarge block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 cursor-pointer" onChange={ handleBeforeChange } />
                                </div>
                            </div>
                            <div className="text-center">
                                <h3 className="mb-2">Après</h3>
                                <div className="border p-6 rounded-lg shadow-md">
                                    <input type="file" className="inputLarge block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 cursor-pointer" onChange={ handleAfterChange }/>
                                </div>
                            </div>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">Title</label>
                            <input type="text" className=" inputLarge mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" placeholder="Title" onChange={ handleTitleChange }/>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Description</label>
                            <textarea className="inputLarge mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm" rows="3" placeholder="Description" onChange={ handleContentChange }></textarea>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-200 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default AdminPannel;
