import React, { useRef } from 'react';
import NavBarre from "../component/NavBarre";
import Footer from "../component/Footer";
function ProfessionnelPage() {


    return (
        <div className="containerPagePro">
            <NavBarre/>
            <div className="pageContainer">

                <div className="contentProPage">
                    <h1 data-aos="fade" data-aos-duration="500"  data-aos-once="true" className="text-3xl textGreen">Pourquoi nous choisir ?</h1>
                    <p data-aos="fade" data-aos-duration="800"  data-aos-once="true" className="text-sm">
                        ALC Paysages, votre partenaire professionnel pour sublimer votre environnement
                        d'entreprise. Des services sur mesure au-delà de l'entretien, créant une atmosphère soignée
                        qui reflète la qualité de votre entreprise. Des espaces extérieurs impeccables sont une vitrine
                        de votre professionnalisme. Contactez-nous pour magnifier votre cadre de travail avec
                        excellence.
                    </p>

                    <p data-aos="fade" data-aos-duration="900"  data-aos-once="true" className="text-sm">
                        Distinguez-vous avec ALC Paysages, votre partenaire de confiance pour sublimer
                        l'environnement de votre entreprise. En tant qu'indépendant ou professionnel, possédant un
                        magasin, des bureaux, un entrepôt, ou toute autre structure, nous comprenons l'importance
                        cruciale de maintenir des espaces impeccables.
                    </p>

                    <p data-aos="fade" data-aos-duration="1200"  data-aos-once="true" className="text-sm">
                        ALC Paysages vous propose des services sur mesure, allant bien au-delà du simple entretien.
                        Nous sommes là pour créer une atmosphère accueillante et soignée qui reflète la qualité de
                        votre entreprise. Des espaces extérieurs bien entretenus sont une vitrine de votre
                        professionnalisme, et c'est là que notre expertise entre en jeu.
                    </p>

                    <p data-aos="fade" data-aos-duration="1500"  data-aos-once="true" className="text-sm">
                        Que ce soit pour des tontes minutieuses, des tailles de haies, le nettoyage des espaces verts,
                        ou toutes autres prestations horticole, nous sommes là pour magnifier votre cadre de travail.
                        Chez ALC Paysages, nous comprenons que chaque détail compte, et c'est pourquoi notre
                        équipe s'engage à offrir des services personnalisés qui répondent parfaitement à vos besoins
                        spécifiques.
                    </p>

                    <p data-aos="fade" data-aos-duration="1700"  data-aos-once="true" className="text-sm">
                        Faites de votre espace professionnel un lieu où l'esthétique raffinée rencontre la
                        fonctionnalité pratique. Optez pour l'excellence avec ALC Paysages, et démarquez-vous dans
                        le monde professionnel. Contactez-nous dès aujourd'hui pour discuter de la façon dont nous
                        pouvons optimiser l'aspect extérieur de votre entreprise et créer une première impression
                        mémorable pour vos clients et collaborateurs.
                    </p>
                    <a  href="/contact" data-aos="fade-up"   data-aos-once="true" style={{width: "60%"}}>
                        <button  className="buttonSendForm" style={{width: "100%"}}>

                            Nous contacter
                   </button> </a>
            </div>


        </div>
            <Footer/>
        </div>
    );
}

export default ProfessionnelPage;
