import React, { useEffect, useState } from 'react';
import icoCross from "../assets/Icones/cross.svg";

const Gallery = () => {
    const [popupImage, setPopupImage] = useState(null);
    const [imageRealisation, setImageRealisation] = useState([]);

    useEffect(() => {
        fetch('https://alc-paysages.fr/api/realisationAllImg', {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to get image');
                }
            })
            .then(result => {
                const images = result.reduce((acc, item) => {
                    if (item.realisation !== null) {
                        acc.push(item.realisation);
                    }
                    return acc;
                }, []);
                setImageRealisation(images);
            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred while fetching images');
            });
    }, []);

    const tabReverse = [...imageRealisation].reverse();

    const openPopUp = (image) => {
        setPopupImage(image);
    };

    const closePopUp = () => {
        setPopupImage(null);
    };

    const deleteRealisation = (image) => {

        const result = window.confirm("Souhaitez-vous supprimer l'image ?");


        if(result === false){
            return;
        }

        const token = localStorage.getItem('token');

        const body = JSON.stringify({
            url: image,
        });

        fetch('https://alc-paysages.fr/api/deleteRealisation', {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: body
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to delete image');
                }
            })
            .then(result => {
                alert('Realisation deleted successfully');
                setImageRealisation(imageRealisation.filter(img => img !== image));
                closePopUp();
            })
            .catch(error => {
                console.error('Error:', error);
                alert('An error occurred while deleting the realisation');
            });
    };

    useEffect(() => {
        if(document.getElementsByClassName("deleteReal")[0]){
            if(!localStorage.getItem("token")){
                 document.getElementsByClassName("deleteReal")[0].style.display="none";
            }
        }
    }, [popupImage]);

    return (
        <div data-aos="fade" data-aos-duration="1500" data-aos-once="true" className="gallery-container">
            {tabReverse.map((image, index) => (
                <img
                    key={index}
                    onClick={() => openPopUp(image)}
                    className="gallery-image"
                    src={image}
                    alt="Image d'une réalisation de l'entreprise"
                    data-aos="fade-up" data-aos-once="true"
                />
            ))}
            {popupImage && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <img src={popupImage} className="popup-image" alt="Image d'une réalisation de l'entreprise"/>
                    </div>
                    <button onClick={closePopUp} className="popup-close-button">
                        <img src={icoCross} className="croixPopup" alt="Icone d'une croix pour fermer la pop-up"/>
                    </button>
                    <button onClick={() => deleteRealisation(popupImage)} className="deleteReal">
                        Delete
                    </button>
                </div>
            )}
        </div>
    );
};

export default Gallery;
