import React from 'react';
import logo from "../assets/Icones/logo.png"
function Footer() {

    return (
        <footer className="blocFooter">
            <img src={logo} className="logoFooter" alt="Logo de l'entreprise"/>
            <div className="blocCopyRight">
                <p className="textRightFooter text-xs">Copyright. Tous droits réservés.</p>
                <p className="textRightFooter text-xs">© 2024 ALC PAYSAGES</p>
                <div className="mentionCgu">
                    <a className="linkCgu text-xs underline" href={"/mentionLegal"}>Mention Légal </a>
                    <p className=" text-xs" style={{width:"1vw", color:"#6f6f6f"}}>-</p>
                    <a className="linkCgu text-xs underline" href={"/cgu"}> CGU</a>
                </div>
            </div>
            <div className="blocDirectionFooter">
                <a className="linkFooter text-sm" href="/">Accueil</a>
                <a className="linkFooter text-sm" href="/services">Services</a>
                <a className="linkFooter text-sm" href="/realisations">Réalisations</a>
                <a className="linkFooter text-sm" href="/professionnel">Professionel</a>
                <a className="linkFooter text-sm" href="/contact">Obtenir un devis</a>
            </div>
        </footer>
    );
}

export default Footer;
