import NavBarre from "../component/NavBarre";

function Page404(){


    return(
        <div className={'containerPage'}>
            <NavBarre/>
            <div className="pageContainer">
                <div id="notfound">
                    <div className="notfound">
                        <div className="notfound-bg">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <h1>oops!</h1>
                        <h2>Error 404 : Cette page n'existe pas</h2>
                        <a className="toto" href="/">Retour à la page d'accueil</a>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default Page404;
