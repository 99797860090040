import './App.css';
import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./Page/LandingPage";
import ServicePage from "./Page/ServicePage";
import RealisationPage from "./Page/RealisationPage";
import ProfessionnelPage from "./Page/ProfessionnelPage";
import AdminPannel from "./Page/adminPannel";
import Login from "./Page/login";
import ProtectedRoute from "./protectedRoutes";
import MentionLegal from './Page/mentionLegal';
import Cgu from "./Page/Cgu";
import Page404 from "./Page/404";
import Contact from "./Page/contactPage";
import LoadingScreen from "./Page/loadingScreen";


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      setLoading(false);
    };

    if (document.readyState === 'complete') {
      handleLoad();
    } else {
      window.addEventListener('DOMContentLoaded', handleLoad);
      window.addEventListener('load', handleLoad);
    }

    return () => {
      window.removeEventListener('DOMContentLoaded', handleLoad);
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  const router = createBrowserRouter([
    { path: '/', element: <LandingPage /> },
    { path: '/services', element: <ServicePage /> },
    { path: '/realisations', element: <RealisationPage /> },
    { path: '/professionnel', element: <ProfessionnelPage /> },
    { path: '/adminPannel', element: <ProtectedRoute element={AdminPannel} /> },
    { path: '/login', element: <Login /> },
    { path: '/mentionLegal', element: <MentionLegal /> },
    { path: '/cgu', element: <Cgu /> },
    { path: '/contact', element: <Contact /> },
    { path: '/404', element: <Page404 /> },
    { path: '*', element: <Page404 /> }
  ]);

  return (
      <>
        {loading ? <LoadingScreen /> : <RouterProvider router={router} />}
      </>
  );
}

export default App;
