import React from 'react';
import NavBarre from "../component/NavBarre";
import Footer from "../component/Footer";
import aerienne from "../assets/asset6.jpg";
import "../../src/css/service.css";
import asset7 from "../assets/asset7.jpg";
import asset8 from "../assets/asset8.jpeg";
import icoMap from "../assets/Icones/mapIco2.svg"
import MapComponent from "../component/MapComponent";
import AccordionComponent from "../component/Accordeon";
import logo from "../assets/Icones/logo.png"
import ScrollTop from "../component/ScrollTop";
import asset9 from "../assets/asset9.png"


function ServicePage() {


    return (
        <div className="containerPage">
            <NavBarre/>
            <div className="pageContainer">
                <div className="bloc1Service">
                    <img src={aerienne} data-aos="fade-right" data-aos-once="true" className="img1QuiBis" alt="Image d'un jardin qui illustre le texte"/>
                    <div data-aos="fade-up" data-aos-once="true" className="blocContentQuiService">
                        <h3 className="text-3xl">ALC Paysages - Entretien Parc & Jardin</h3>

                        <p>Confiez la beauté de vos espaces verts à ALC Paysages.<br/>
                            Notre équipe dévouée prend en charge l'entretien expert de vos<br/>
                            jardins, garantissant une verdure luxuriante, une esthétique<br/>
                            impeccable et un service personnalisé adapté à vos besoins<br/>
                            spécifiques.</p>

                        <a href="/contact">
                            <button className="contact-button text-xs">
                                Nous contacter
                            </button>
                        </a>
                    </div>
                    <img src={aerienne} data-aos="fade-right" data-aos-once="true" className="img1QuiBisQuery" alt="Image d'un jardin qui illustre le texte"/>
                </div>

                <div className="bloc2Service">
                    <div className="blocContentServ">
                        <div className="contentServContent" data-aos="fade-right" data-aos-once="true">
                            <h3 className="text-3xl textGreen">ALC Paysages - Élagage d'Arbres</h3>
                            <div className="blocTextQui">
                                <p>Confiez la vitalité de vos arbres à ALC Paysages. Avec
                                    une expertise attentive en élagage, nous préservons
                                    la forme, la santé et l'esthétique de chaque arbre, créant
                                    ainsi un environnement où la nature s'épanouit avec
                                    élégance.</p>
                            </div>
                            <a href="/realisations">
                                <button className="contact-button text-xs">
                                    Voir nos réalisations
                                </button>
                            </a>
                        </div>


                        <img src={asset9} className="img2Qui" data-aos="fade-up" data-aos-once="true" alt="Image qui illustre l'élagage d'un arbre"/>
                    </div>
                    <img src={asset7} data-aos="fade-up" data-aos-once="true" className="imgLongueService" alt="Deuxième image qui illustre l'élagage d'un arbre"/>
                </div>

                <div className="contentBloc3Serv">
                    <h1 className="text-3xl" data-aos="fade-up" data-aos-once="true">ALC Paysages - Tonte &
                        Débroussaillages</h1>
                    <p className="text-sm" data-aos="fade-up" data-aos-once="true">
                        Offrez à votre pelouse le soin qu'elle mérite avec ALC Paysages. Nous nous engageons à assurer
                        une tonte précise, une fertilisation équilibrée et une gestion attentive pour une verdure
                        luxuriante qui transforme votre jardin en un espace de détente impeccable.
                    </p>
                    <a href="/contact">
                        <button data-aos="fade-up" data-aos-once="true" className="contact-button text-xs">
                            Nous contacter
                        </button>
                    </a>
                </div>
            </div>
            <img src={asset8} className="imgLonguePage" alt="Longue image qui illustre une tonte de pelouse"/>

            <div className="blocTitleMap"id="map">
                <img src={icoMap} className="mapIco" alt="Icône d'un point gps"/>
                <h2 className="textGreen text-2xl">Zone d'intervention</h2>
            </div>
            <div className="blocMap" >
                <div className="blocMapPrinc">
                    <div className="mapComponentBloc">
                        <MapComponent/>
                    </div>

                    <div className="contentMapAcco">
                        <AccordionComponent/>
                        <div data-aos="fade-up" data-aos-once="true" className="blocFinContact">
                            <img src={logo} className="logoFin" alt="Logo de l'entreprise"/>
                            <a href="/contact">
                                <button className="contact-button text-xs">
                                    Nous contacter
                                </button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <ScrollTop/>
            <Footer/>
        </div>
    );
}

export default ServicePage;
