import React, {useEffect, useState} from 'react';
import icoFacebookGreen from "../assets/Icones/facebookGreen.svg";
import icoInstaGreen from "../assets/Icones/instagramGreen.svg";
import icoMailGreen from "../assets/Icones/mailGreen.svg";
import icoInsta from "../assets/Icones/instagram.svg";
import icoFacebook from "../assets/Icones/facebook.svg";
import icoMail from "../assets/Icones/mail.svg";
import {useTheme} from "../ModeContext";
import instDark from "../assets/Icones/instagramDark.svg";
import faceDark from "../assets/Icones/facebookDark.svg";
import mailDark from "../assets/Icones/mailDark.svg";

const Menu = ({ isOpen, closeMenu }) => {

    const [srcInsta, setSrcInsta] = useState(icoInsta);
    const [srcFace, setSrcFace] = useState(icoFacebook);
    const [srcMail, setSrcMail] = useState(icoMail);

    const [instaMode, setInstaMode] = useState(icoInsta);
    const [faceMode, setFaceMode] = useState(icoFacebook);
    const [mailMode, setMailMode] = useState(icoMail);

    const { theme,setTheme } = useTheme();

    useEffect(() => {
        if(theme==="dark"){
            setInstaMode(instDark);
            setFaceMode(faceDark);
            setMailMode(mailDark);

            setSrcInsta(instDark);
            setSrcFace(faceDark);
            setSrcMail(mailDark);
        }else{
            setInstaMode(icoInsta);
            setFaceMode(icoFacebook);
            setMailMode(icoMail);

            setSrcInsta(icoInsta);
            setSrcFace(icoFacebook);
            setSrcMail(icoMail);
        }
    }, [theme]);


    return (
        <div className={`menu ${isOpen ? 'open' : ''}`}>
            <a href="/">Accueil</a>
            <a href="/services">Services</a>
            <a href="/realisations">Réalisations</a>
            <a href="/professionnel">Professionnel</a>
            <a href="/contact" onClick={closeMenu}>Obtenir un devis</a>
            <div className="blocContentSocial" style={{paddingLeft: "15px"}}>
                <h3 className="text-xl">Email :</h3>
                <a className="text-sm" href="mailto:alc.paysages@gmail.com"
                   target="_blank" style={{width: "fit-content" , borderBottom:"none"}}><strong>alc.paysages@gmail.com</strong></a>
            </div>
            <div className="blocContentSocial" style={{paddingLeft: "15px"}}>
                <h3 className="text-xl">Téléphone :</h3>
                <a className="text-sm" href="tel:+0652611391"
                   target="_blank" style={{width: "fit-content" , borderBottom:"none"}}><strong>06 52 61 13 91</strong></a>
            </div>

            <div className="blocContentSocial" style={{paddingLeft: "15px"}}>
                <h3 className="text-xl">Réseaux :</h3>
                <div className="blocSocialContact">
                    <a className="linkSocial"
                       style={{ borderBottom:"none"}}
                       onMouseEnter={() => setSrcFace(icoFacebookGreen)}
                       onMouseLeave={() => setSrcFace(faceMode)}
                       href="https://www.facebook.com/profile.php?id=61556932118026"
                       target="_blank"
                       rel="noopener noreferrer">
                        <img className="icoLink" src={srcFace} alt="Icone qui redirige vers Facebook au clique"/>
                    </a>
                    <a className="linkSocial"
                       style={{ borderBottom:"none"}}
                       onMouseEnter={() => setSrcInsta(icoInstaGreen)}
                       onMouseLeave={() => setSrcInsta(instaMode)}
                       href="https://www.instagram.com/alc_paysages/" target="_blank"
                       rel="noopener noreferrer">
                        <img className="icoLink" src={srcInsta} alt="Icone qui redirige vers Instagram au clique"/>
                    </a>
                    <a className="linkSocial"
                       style={{ borderBottom:"none"}}
                       onMouseEnter={() => setSrcMail(icoMailGreen)}
                       onMouseLeave={() => setSrcMail(mailMode)}
                       href="mailto:alc.paysages@gmail.com" target="_blank"
                       rel="noopener noreferrer">
                        <img className="icoLink" src={srcMail} alt="Icone qui redirige vers votre boîte mail au clique"/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Menu;
