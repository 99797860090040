import React, { useState, useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FaExchangeAlt } from 'react-icons/fa';
import NavBarre from "../component/NavBarre";
import Footer from "../component/Footer";
import Gallery from "../component/GaleryReal";
import Comparaison from "../component/comparaison";
import ScrollTop from "../component/ScrollTop";

function RealisationPage() {
    const [title, setTitle] = useState("Réalisations");
    const [secondTitle, setSecondTitle] = useState("Avant / Après");
    const [inProp, setInProp] = useState(true);
    const nodeRef = useRef(null);

    function changeTitle() {
        setInProp(false);
        if (title === "Réalisations") {
            setTitle("Avant / Après");
            setSecondTitle("Réalisations");
        } else {
            setTitle("Réalisations");
            setSecondTitle("Avant / Après");
        }
        setInProp(true);
    }

    return (
        <div className="containerPage">
            <NavBarre />
            <div className="pageContainerRealisation">
                <div className="blocRealisation">
                    <SwitchTransition>
                        <CSSTransition
                            nodeRef={nodeRef}
                            key={title}
                            classNames="fade"
                            timeout={200}
                        >
                            <h1 ref={nodeRef} data-aos="fade-right" data-aos-once="true" className="text-4xl titleRealQueri textGreen">{title}</h1>
                        </CSSTransition>
                    </SwitchTransition>
                    <button onClick={changeTitle} data-aos="fade-right" data-aos-once="true" className="text-sm transitionButton">
                        <FaExchangeAlt />
                        {secondTitle} (Clique moi)
                    </button>

                    {title === "Réalisations" ? <Gallery /> : <Comparaison />}

                </div>
            </div>
            <ScrollTop />
            <Footer />
        </div>
    );
}

export default RealisationPage;
