import React from 'react';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    Circle,
    LayerGroup,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import L from 'leaflet';

const iconPerson = new L.Icon({
    iconUrl: require('../assets/Icones/pointGps.png'),
    iconRetinaUrl: require('../assets/Icones/pointGps.png'),
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 30),
});

export { iconPerson };


const MapComponent = () => {

    const center = [48.5827, 2.5792]
    const position = [48.595868 , 2.579895]

    return (
        <MapContainer center={center} zoom={9} scrollWheelZoom={false} className="mapCompo">
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
                    <LayerGroup>
                        <Circle
                            center={center}
                            pathOptions={{ fillColor: 'blue' }}
                            radius={20000}
                            stroke={true}
                        />
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                            <Marker position={position}  icon={iconPerson}>
                                <Popup>
                                    A pretty CSS3 popup. <br /> Easily customizable.
                                </Popup>
                            </Marker>
                    </LayerGroup>

        </MapContainer>
    )
};

export default MapComponent;
